import React from "react"
import styled from "styled-components"
import Seo from "@components/Seo"

import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Helmet from "react-helmet"
import { AjaxForm } from "@components/forms/AjaxForm"
import { Link } from "gatsby"
import { scrollTo } from "@components/utils/helpers"

//style
const PageContent = styled.div`
  position: relative;
  overflow: hidden;


  .container {
    display: flex;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
    }
  }


  .content-col, .form-col {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex: 0 0 50%;
      width: 50%;
    }

    position: relative;
  }

  .content-col {
    background-color: #9B1D4A;
    padding: 40px 0 40px 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 150vw;
      height: 100%;
      background-color: #9B1D4A;
      pointer-events: none;
      z-index: -1;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        top: 0;
        right: 0;
        left: auto;
        width: 50vw;
        height: 100%;
        transform: translateX(0);
      }
    }

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 91px 91px 91px 0;
    }

    * {
      color: white;
    }

    .title {
      &:before {
        content: '';
        display: block;
        width: 127px;
        height: 21px;
        margin-bottom: 20px;

        background: var(--color-secondary);
      }

      h1 {
        font-weight: 500;
        display: block;
        font-size: 24px;
        line-height: 48px;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          font-size: 36px;
          line-height: 58px;
        }
      }
    }

    .click-here {
      font-size: 16px;
      line-height: 38px;
      display: inline-block;
      margin-bottom: 15px;

      &:after {
        content: '';
        border-bottom: 1px solid var(--color-secondary);
        display: block;
      }

      ${breakpointsMedia(breakpoints.tabletWide)} {
        display: none;
      }
    }


    .copy {
      font-weight: 400;
      font-size: 15px;
      line-height: 38px;
      margin-top: 15px;
      margin-bottom: 20px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 16px;
        line-height: 38px;
        margin-bottom: 45px;
      }
    }

    .points {
      margin-top: 15px;
      margin-bottom: 45px;
      font-size: 15px;
      line-height: 38px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 16px;
      }

      ul {
        list-style: none;
      }

      li {
        margin-bottom: 7px;
        position: relative;
        padding-left: 13px;

        &:before {
          content: '';
          border-radius: 100%;
          height: 11px;
          width: 11px;
          display: inline-block;
          background: var(--color-secondary);
          position: absolute;
          top: .8rem;
          left: -10px;
        }
      }
    }

    .meta-list {
      .meta {
        i {
          margin-right: 5px;
        }

        margin-bottom: 5px;
        font-weight: 700;
        font-size: 16px;
        line-height: 38px;
      }
    }
  }

  .form-col {
    padding: 40px 0;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 91px 0px 91px 41px;
    }

    .title {
      font-weight: 700;

      display: block;
      margin-bottom: 15px;

      font-size: 24px;
      line-height: 48px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 32px;
        line-height: 37px;
      }
    }

    .terms {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      a {
        color: var(--color-primary-darkened);
      }
    }
  }
`

export default function Page() {
  const scrollToForm = () => {
    scrollTo(document.querySelector(`#form_col`), true)
  }
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "short"
        }}
      />
      <Seo title="In-person SEO training for journalists | Passion Digital"
           description="Registration page for the journalists SEO training" />

      <PageContent>
        <div className="container">
          <div className="content-col">
            <div className="title">
              <h1>Interested in joining in-person SEO Training for Journalists, sign up for the waiting list</h1>
            </div>
            <div className="click-here" onClick={scrollToForm}>
              Click here to register
            </div>

            <div className="copy">
              The role of the journalist is no longer limited to writing a story and sharing it with an editor. The
              growth of digital content had its effect on how journalists research and write their news stories.
              Understanding your audience and what content increases readership is key for successful content planning.
            </div>

            <div className="points">
              <p><strong>Join this in-person training to learn</strong></p>
              <ul>
                <li>Why is SEO valuable to journalists?</li>
                <li>Understanding keywords & search intent</li>
                <li>Free analysis tools</li>
                <li>How to write to increase search traffic and readership</li>
                <li>Technical SEO: What’s important for journalists</li>
              </ul>
            </div>

            <div className="meta-list">
              <div className="meta">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                    <path
                      d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5Z"
                      fill="#EA9723" />
                  </svg>
                </i> <span>Event date: TBC</span>
              </div>
              <div className="meta">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                    <path
                      d="M4 4.625C4 4.84375 4.15625 5 4.375 5H5.625C5.8125 5 6 4.84375 6 4.625V3.375C6 3.1875 5.8125 3 5.625 3H4.375C4.15625 3 4 3.1875 4 3.375V4.625ZM8.375 5H9.625C9.8125 5 10 4.84375 10 4.625V3.375C10 3.1875 9.8125 3 9.625 3H8.375C8.15625 3 8 3.1875 8 3.375V4.625C8 4.84375 8.15625 5 8.375 5ZM4.375 8H5.625C5.8125 8 6 7.84375 6 7.625V6.375C6 6.1875 5.8125 6 5.625 6H4.375C4.15625 6 4 6.1875 4 6.375V7.625C4 7.84375 4.15625 8 4.375 8ZM8.375 8H9.625C9.8125 8 10 7.84375 10 7.625V6.375C10 6.1875 9.8125 6 9.625 6H8.375C8.15625 6 8 6.1875 8 6.375V7.625C8 7.84375 8.15625 8 8.375 8ZM6 10.625V9.375C6 9.1875 5.8125 9 5.625 9H4.375C4.15625 9 4 9.1875 4 9.375V10.625C4 10.8438 4.15625 11 4.375 11H5.625C5.8125 11 6 10.8438 6 10.625ZM8.375 11H9.625C9.8125 11 10 10.8438 10 10.625V9.375C10 9.1875 9.8125 9 9.625 9H8.375C8.15625 9 8 9.1875 8 9.375V10.625C8 10.8438 8.15625 11 8.375 11ZM14 14.875C14 14.6875 13.8125 14.5 13.625 14.5H13V0.75C13 0.34375 12.6562 0 12.25 0H1.71875C1.3125 0 0.96875 0.34375 0.96875 0.75V14.5H0.375C0.15625 14.5 0 14.6875 0 14.875V16H14V14.875ZM2.46875 14.4688L2.5 1.5L11.5 1.53125V14.4688H8V12.375C8 12.1875 7.8125 12 7.625 12H6.375C6.15625 12 6 12.1875 6 12.375V14.4688H2.46875Z"
                      fill="#EA9723" />
                  </svg>
                </i> <span>Venue: TBC</span>
              </div>
            </div>
          </div>
          <div className="form-col" id="form_col">
            <div className="title">
              Registration
            </div>
            <AjaxForm
              formName="seo_training"
              formSubject="SEO Training for Journalists"
              submitText={"Register"}
              onSubmitOk={data => {
                window.dataLayer.push({ event: "gtm.formRegister" })
              }}
            >
              <div className="form-row">
                <AjaxForm.TextInput
                  label="First Name"
                  name="first_name"
                  required={true}
                  maxLength={100}
                />
                <AjaxForm.TextInput
                  label="Last Name"
                  name="last_name"

                  required={true}
                  maxLength={100}
                />
              </div>

              <div className="form-row">
                <AjaxForm.EmailInput
                  label="Email"
                  name="email"
                  required={true}
                  maxLength={100}
                />
                <AjaxForm.TextInput
                  label="Phone Number"
                  name="phone_number"
                  required={true}
                  maxLength={100}
                />
              </div>

              <div className="form-row">
                <AjaxForm.TextInput
                  label="Organisation"
                  name="organisation"
                  maxLength={100}
                  required={true}
                />
                <AjaxForm.TextInput
                  label="Job Role"
                  name="job_role"
                  maxLength={100}
                  required={true}
                />
              </div>

              <div className="terms">
                Information you provide when registering will be shared with the account owner and host and can be used
                and shared by them in accordance with their <Link href="/privacy-policy/">Terms and Privacy
                Policy</Link>.
              </div>

            </AjaxForm>
          </div>
        </div>
      </PageContent>
    </>
  )
}
